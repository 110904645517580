import {
  JsonScalar,
  flatten,
  ifArray,
  ifString,
  isArray,
  isDefined,
  isJsonScalar,
  isString,
  parseFilename,
} from '@penbox-io/stdlib'

import {
  type Dict,
  type FormHelpable,
  ifFormHelpable,
  stringifyHelpable,
  asDict,
  isDict,
} from '../../common'
import { type Definition, type GenericElement } from '../../core'

import { type FormError, buildError } from '../utils/error.js'
import { ifUri, UriString } from '../utils/url.js'
import { parseEnum } from '../utils/enum.js'

type Value = {
  status: SignatureStatus
  name?: string
  type?: string
  external?: {
    signers?: { email?: string; completed_at?: string }[]
  }
  user?: {
    email: string
  }
  email?: string
} & {
  // Note, Value might contain other implementation specific fields.
  [key: string]: unknown
}

export enum SignatureStatus {
  draft = 'draft',
  pending = 'pending',
  signed = 'signed',
  finished = 'finished',
  rejected = 'rejected',
  failed = 'failed',
}

export enum SignatureMethod {
  sms = 'sms',
  email = 'email',
  handwritten = 'handwritten',
  idCard = 'id-card',
  itsme = 'itsme',
}

export type SignatureItem<Protocol extends string = string> = {
  id?: string
  uri: UriString<Protocol>
  name?: string
  fill?: Dict<JsonScalar>
  signatures?: Signature[]
}

export type Signature = SignatureCoordinates | SignatureField | SignatureHint

export type SignatureHint = {
  hint: string | [string, ...string[]]
}

export type SignatureField = {
  field: string
}

export type SignatureCoordinates = {
  page: number
  left: number
  top: number
  width?: number
  height?: number
}

export type SignatureSigner = {
  given_name: string
  family_name: string
  email: string
  phone?: string
  locale?: string
  signature_hint?: string
}

type Options = {
  label?: FormHelpable
  allowRejection: boolean

  items: SignatureItem[]
  method: SignatureMethod
  merge: boolean
  name?: string
  locale?: string
  submitOnChange?: boolean
  hideFiles?: boolean
  additionalSigners: SignatureSigner[]
}

type Element = GenericElement<Options, Value>

export default {
  options,
  parse,
  requirable,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  return {
    items: parseItems(input.items) || [],
    label: ifFormHelpable(input.label),
    merge: input.merge !== false,
    method: parseEnum(SignatureMethod, input.method) || SignatureMethod.sms,
    name: ifString(input.name) || undefined,
    locale: ifString(input.locale) || undefined,
    allowRejection: input.allow_rejection === true,
    submitOnChange: input.submit_on_change === true,
    hideFiles: input.hide_files === true,
    additionalSigners: parseAdditionalSigners(input.additional_signers),
  }
}

function requirable({ items }: Options): boolean {
  return items.length > 0
}

function parse(options: Options, locale: string, input: unknown): null | Value {
  return isValue(input) ? input : null
}

function isValue(value: unknown): value is Value {
  if (!isDict(value)) return false

  if (value.name != null && typeof value.name !== 'string') {
    return false
  }

  if (value.type != null && typeof value.type !== 'string') {
    return false
  }

  if (parseEnum(SignatureStatus, value.status) !== value.status) {
    return false
  }

  return true
}

function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  if (!value) {
    return null
  }

  const isV2 = value.external?.signers != null
  // if v2, we search for a completed at date on the list of signers
  const isSigned = !isV2
    ? value?.status === SignatureStatus.signed || value?.status === SignatureStatus.finished
    : value.external?.signers?.find((x) => x.email === value?.email)?.completed_at !== undefined

  if (options.allowRejection && value?.status === SignatureStatus.rejected) {
    return null
  }

  if (required && !isSigned) {
    return buildError('requiredSignature', locale)
  }

  return null
}

function normalize(element: Element): undefined | Value {
  return element.value || undefined
}

function stringify(element: Element): undefined | string {
  const value = normalize(element)
  if (!value) return undefined

  const name = value.name || '<un-named>'

  return `${name} (${value.status})`
}

function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.title)
}

// private methods

function parseItems(input: unknown): SignatureItem[] {
  if (!isArray(input)) return []
  return Array.from(flatten(input), parseItem).filter(isDefined)
}

function parseItem(input: unknown): SignatureItem | undefined {
  if (isDict(input)) {
    const uri = ifUri(input?.uri)
    if (!uri) return undefined

    return {
      id: ifString(input.id),
      uri,
      name: ifString(input.name) || uriToName(uri),
      fill: input.fill,
      signatures: ifArray(input.signatures)?.map(asSignature).filter(isDefined),
    }
  }

  if (isString(input)) {
    const uri = ifUri(input)
    if (!uri) return undefined

    return {
      uri,
      name: uriToName(uri),
    }
  }

  return undefined
}

function parseAdditionalSigners(input: unknown): SignatureSigner[] {
  if (!isArray(input)) return []
  return Array.from(flatten(input), parseAdditionalSigner).filter(isDefined)
}

function parseAdditionalSigner(input: unknown): SignatureSigner | undefined {
  if (!isDict(input)) return undefined

  const given_name = ifString(input.given_name)
  const family_name = ifString(input.family_name)
  const email = ifString(input.email)
  const phone = ifString(input.phone)
  const locale = ifString(input.locale) ?? 'en'
  const signature_hint = ifString(input.signature_hint)

  if (!given_name || !family_name || !email) return undefined

  return {
    given_name,
    family_name,
    email,
    phone,
    locale,
    signature_hint,
  }
}

function uriToName(uri: string) {
  return parseFilename(uri)[1] || undefined
}

function asSignature(input: unknown): undefined | Signature {
  return asSignatureCoordinates(input) || asSignatureField(input) || asSignatureHint(input)
}

function asSignatureField(input: unknown): undefined | SignatureField {
  if (!isDict(input)) return undefined
  if (!isString(input.field)) return undefined
  return { field: input.field }
}

function asSignatureHint(input: unknown): undefined | SignatureHint {
  if (!isDict(input)) return undefined

  const hintString = ifString(input.hint)
  if (hintString) return { hint: hintString }

  const hintArray = ifArray(input.hint, isString)
  if (hintArray?.length) return { hint: hintArray as [string, ...string[]] }

  return undefined
}

function asSignatureCoordinates(input: unknown): undefined | SignatureCoordinates {
  if (!isDict(input)) return undefined
  if (asValidNumber(input.page, 1) == null) return undefined
  if (asValidNumber(input.left, 0) == null) return undefined
  if (asValidNumber(input.top, 0) == null) return undefined

  return {
    page: input.page,
    left: input.left,
    top: input.top,
    width: asValidNumber(input.width, 1),
    height: asValidNumber(input.height, 1),
  }
}

function asValidNumber(input: unknown, min: number): number | undefined {
  switch (typeof input) {
    case 'number':
      return input >= min ? input : undefined
    default:
      return undefined
  }
}
